import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Stack, TextField, Grid, List, ListItem, Button } from "@mui/material";
import { Done, Edit } from '@mui/icons-material';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import Treemap from 'components/Treemap/Treemap';
import { usePapaParse } from 'react-papaparse';
import { icebergColorsInit, icebergColorsReducer, icebergPastColorsInit, icebergPhasesReducer, icebergProjColorsInit, icebergReducer, icebergSwimlanesInit, icebergSwimlanesReducer } from '../../reducers/IcebergReducer';
import example from '../../assets/data/example.js';
import usePersistantState from 'common/usePersistentState';

const ColorItem = ({ c, i, onChanged }) => {
  const [color, setColor] = useState(c);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: 2 }}>
      <div style={{
        background: color,
        alignSelf: "center",
        justifySelf: "center",
        width: 24,
        height: 24,
      }} />
      <TextField value={color}
        onChange={(e) => setColor(e.target.value)}
        onBlur={(e) => onChanged({ index: i, color: e.target.value })} />
    </div>
  )
}

const Swimlane = ({ swimlane, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ListItem key={swimlane.key}>
      <Grid container alignItems="center" gap={1}>
        <MKTypography>
          {swimlane.key}
        </MKTypography>

        {isEditing ?
          <>
            <Grid item xs={true}>
              <TextField fullWidth
                value={swimlane.title}
                onChange={(e) => {
                  onChange({ ...swimlane, title: e.target.value });
                }}
              />

              <TextField fullWidth
                value={swimlane.description}
                onChange={(e) => {
                  onChange({ ...swimlane, description: e.target.value });
                }}
              />
            </Grid>

            <Grid item xs={1} minWidth={42}>
              <MKButton onClick={() => setIsEditing(false)}
                width={42}>
                <Done />
              </MKButton>
            </Grid>
          </>
          : <>
            <Grid item xs={true}>
              <MKTypography variant='body2'>
                {swimlane.title} - {swimlane.description}
              </MKTypography>
            </Grid>
            <Grid item xs={1} minWidth={64}>
              <MKButton onClick={() => setIsEditing(true)}
                variant="text">
                <Edit />
              </MKButton>
            </Grid>
          </>
        }
      </Grid>
    </ListItem>
  )
}

const IcebergChart = () => {
  const { readString } = usePapaParse();
  const [content, setContent] = usePersistantState("ICEBERG_CONTENT", "");
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  const [iceberg, icebergDispatch] = useReducer(icebergReducer, []);
  const [phases, phasesDispatch] = useReducer(icebergPhasesReducer, []);
  const [swimlanes, swimlanesDispatch] = useReducer(icebergSwimlanesReducer, icebergSwimlanesInit);
  const [colors, colorsDispatch] = useReducer(icebergColorsReducer, icebergColorsInit);
  const [pastColors, pastColorsDispatch] = useReducer(icebergColorsReducer, icebergPastColorsInit);
  const [projectionColors, projectionColorsDispatch] = useReducer(icebergColorsReducer, icebergProjColorsInit);
  const [heightFactor, setHeightFactor] = usePersistantState("ICEBERG_HEIGHT_FACTOR", 5);
  const [width, setWidth] = usePersistantState("ICEBERG_PHASE_WIDTH", 250);
  const [currentPhase, setCurrentPhase] = usePersistantState("ICEBERG_PHASE_CURRENT", 1);

  const chartRef = useRef();

  function nodeToString ( node ) {
    var tmpNode = document.createElement( "div" );
    tmpNode.appendChild( node.cloneNode( true ) );
    var str = tmpNode.innerHTML;
    tmpNode = node = null; // prevent memory leaks in IE
    return str;
 }

  useEffect(() => {
    icebergDispatch({ type: "clear" });
    let newPhases = [];
    const csvParsed = readString(content, { header: true });
    csvParsed.data.forEach((row) => {
      if (newPhases.filter((p) => p.key === row.phase).length === 0) {
        const oldTitle = phases?.filter(pold => pold.key === row.phase)?.title;
        const newPhase = { key: row.phase, title: oldTitle };
        newPhases.push(newPhase);
      }
    });
    icebergDispatch({ type: "init", rows: csvParsed.data });
    phasesDispatch({ type: "init", rows: newPhases });
    // eslint-disable-next-line
  }, [content]);

  function handleChangeSwimlane(sw) {
    swimlanesDispatch({ type: 'changed', ...sw });
  }

  return (
    <Stack direction={"column"} alignItems="stretch">
      <Stack direction="column" alignItems="stretch"
        padding={6} spacing={3}>

        <MKTypography variant='h4'>
          {t('iceberg.title')}
        </MKTypography>

        <MKTypography variant='h5'>
          {t('iceberg.subtitle')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('iceberg.description')}
        </MKTypography>

        <Button variant='contained' onClick={() => {
          setContent(example);
        }}>
          {t('iceberg.example.button')}
        </Button>

        <TextField
          id="contentText" name='contentText'
          label={t('iceberg.content.title')}
          value={content}
          multiline rows={6}
          onChange={(event) => {
            setContent(event.target.value);
          }
          }
        />

        <Button onClick={() => {
          const nodeString = nodeToString(chartRef?.current);
          navigator.clipboard.writeText(nodeString);
        }}>
          {t('iceberg.copy.button')}
        </Button>

        <Stack direction="column" spacing={2}>
          <MKTypography variant='h6'>
            {t('iceberg.chart.title')}
          </MKTypography>

          <MKBox sx={{ background: 'white' }} p={2} key={"chart_box"}>
            <div key={"chart_container"} ref={chartRef}
              style={{
                display: "grid",
                gridTemplateRows: "repeat(" + (swimlanes.length + 1) + ", auto)",
                gridTemplateColumns: "repeat(" + (phases.length + 1) + ", auto)",
                overflowX: "scroll"
              }}>
              <div key={"top_left_empty"}></div>
              {phases.map((p) => {
                return (
                  <div key={"p_" + p.key + "_title"} style={{
                    alignContent: "center"
                  }}>
                    {p.title ?? p.key}
                  </div>
                );
              })}

              {swimlanes.map((sw, i) => (
                <>
                  <div key={"sw_" + sw.key + "_title"} style={{
                    display: "grid", gridTemplateRows: "auto 1fr",
                    padding: 5,
                    alignSelf: sw.key === "done" ? "end" : undefined,
                    borderBottom: i < swimlanes.length ? "2px solid black" : undefined
                  }}>
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>
                      {sw.title}
                    </span>
                    <span style={{ fontSize: 10 }}>
                      {sw.description}
                    </span>
                  </div>
                  {phases.map((p) => {
                    const data = iceberg.filter((ib) => ib.phase === p.key && ib.status === sw.key).sort((a, b) => a.priority - b.priority);
                    const adjustedColors = p.key < currentPhase
                      ? pastColors // past
                      : p.key > currentPhase
                        ? projectionColors // future
                        : colors;

                    return (
                      <div key={"sw_" + sw.key + "_" + p.key} style={{
                        alignSelf: sw.key === "done" ? "end" : undefined,
                        borderBottom: i < swimlanes.length ? "2px solid black" : undefined
                      }}>
                        <Treemap width={width} heightFactor={heightFactor} data={data} colors={adjustedColors} />
                      </div>
                    );
                  })}
                </>
              ))}
            </div >
          </MKBox>
        </Stack>

        <Stack padding={1} spacing={3}>
          <MKTypography variant="h6">
            {t('iceberg.config.title')}
          </MKTypography>

          <TextField id="current_phase"
            label={t('iceberg.currentPhase.title')}
            value={currentPhase}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setCurrentPhase(isNaN(parsed) ? 2 : parsed);
            }} />

          <TextField id="height_factor"
            label={t('iceberg.heightFactor.title')}
            value={heightFactor}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setHeightFactor(isNaN(parsed) ? 2 : parsed);
            }} />

          <TextField id="width"
            label={t('iceberg.width.title')}
            value={width}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setWidth(isNaN(parsed) ? 2 : parsed);
            }} />

          <MKTypography variant="h6">
            {t('iceberg.swimlanes.title')}
          </MKTypography>

          <List>
            {swimlanes && swimlanes.length > 0 ?
              swimlanes.map(sw => (
                <Swimlane swimlane={sw} key={sw.key}
                  onChange={handleChangeSwimlane} />
              ))
              : <Stack>
                {t('iceberg.swimlanes.empty')}
              </Stack>}
          </List>

          <MKTypography variant="h6">
            {t('iceberg.phases.title')}
          </MKTypography>

          {phases.map((p) => (
            <TextField label={p.key} value={p.title} onChange={(e) => phasesDispatch({ type: "changed", ...p, title: e.target.value })} />
          ))}

          <MKTypography variant="h6">
            {t('iceberg.colors.title')}
          </MKTypography>

          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(" + colors.length + ", 1fr)",
            gridTemplateRows: "auto auto auto",
            gap: 2,
          }}>
            {pastColors.map((c, i) => (
              <ColorItem c={c} i={i} onChanged={(payload) => pastColorsDispatch({ type: "changed", ...payload })} />
            ))
            }
            {colors.map((c, i) => (
              <ColorItem c={c} i={i} onChanged={(payload) => colorsDispatch({ type: "changed", ...payload })} />
            ))
            }
            {projectionColors.map((c, i) => (
              <ColorItem c={c} i={i} onChanged={(payload) => projectionColorsDispatch({ type: "changed", ...payload })} />
            ))
            }
          </div>
        </Stack>
      </Stack>

      <Stack direction="column" alignItems="stretch" sx={{ background: darkMode ? "dark" : "lightgray" }}
        width="stretch" padding={6} spacing={3}>
        <MKTypography variant='h6'>
          {t('iceberg.faq.title')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('iceberg.faq.why.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('iceberg.faq.why.answer')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('iceberg.faq.how.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('iceberg.faq.how.answer')}
        </MKTypography>

        <MKTypography variant='h6'>
          {t('iceberg.faq.data.question')}
        </MKTypography>

        <MKTypography variant='body2'>
          {t('iceberg.faq.data.answer')}
        </MKTypography>
      </Stack>
    </Stack>
  )
}

export default IcebergChart;