exports.icebergReducer = (iceberg, action) => {
  switch (action.type) {
    case 'added': {
      return [...iceberg, {
        type: "leaf",
        name: action.id,
        value: action.weight,
        priority: action.prio,
        title: action.title,
        phase: action.phase,
        status: action.status,
        group: action.group,
      }];
    }
    case 'init': {
      return action.rows.map((r) => {
        return {
          type: "leaf",
          name: r.id,
          value: r.weight,
          priority: r.prio,
          title: r.title,
          phase: r.phase,
          status: r.status,
          group: r.group
        };
      });
    }
    case 'clear': {
      return [];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

exports.icebergSwimlanesInit = [
  { key: "done", title: "Done", description: "All done and dusted", order: 1 },
  { key: "in qa", title: "Testing", order: 2 },
  { key: "in dev", title: "Developing", order: 3 },
  { key: "todo", title: "Todo", order: 4 }
];
exports.icebergSwimlanesReducer = (swimlanes, action) => {
  switch (action.type) {
    case 'added': {
      return [...swimlanes, {
        key: action.key, title: action.title, description: action.description, order: action.order
      }].sort((a, b) => a.order - b.order);
    }
    case 'changed': {
      return [...swimlanes.filter((s) => s.key !== action.key), {
        key: action.key, title: action.title, description: action.description, order: action.order
      }].sort((a, b) => a.order - b.order);
    }
    case 'removed': {
      return [...swimlanes.filter((s) => s.key !== action.key)];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

exports.icebergColorsInit = ["#AAAAAA", "#3D8D7A", "#C14600", "#56021F", "#2C2C2C"];
exports.icebergPastColorsInit = ["#888888", "#1D6D5A", "#A12600", "#36021F", "#0C0C0C"];
exports.icebergProjColorsInit = ["#CCCCCC", "#6DBDAA", "#F17630", "#86324F", "#4C4C4C"];
exports.icebergColorsReducer = (colors, action) => {
  switch (action.type) {
    case 'changed': {
      return colors.map((c, i) => i === action.index ? action.color : c);
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

exports.icebergPhasesReducer = (phases, action) => {
  switch (action.type) {
    case 'added': {
      return [...phases, {
        key: action.key,
        title: action.title,
        order: action.order
      }].sort((a, b) => a.order - b.order);
    }
    case 'init': {
      return action.rows.map((r, i) => {
        return { ...r, order: i}
      });
    }
    case 'clear': {
      return [];
    }
    case 'changed': {
      return [...phases.filter((p) => p.key !== action.key), {
        key: action.key, title: action.title, order: action.order
      }].sort((a, b) => a.order - b.order);
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};