/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// Pages
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import { Computer, Dashboard, RoomService } from "@mui/icons-material";
import PasswordGeneratorPage from "pages/client_only/PasswordGenerator";
import { default as DashboardPage } from "pages/Dashboard";
import QRCodify from "pages/client_only/QRCodify";
import Compress from "pages/client_only/Compress";
import TaskList from "pages/client_only/TaskList";
import MindMap from "pages/client_only/MindMap";
import UptimeDashboard from "pages/uptime/UptimeDashboard";
import SignOut from "pages/landing_pages/SignOut";
import EULA from "pages/legal/EULA";
import Privacy from "pages/legal/Privacy";
import Licenses from "pages/legal/Licenses";
import MemberAccountPage from "pages/member/MemberAccount";
import FeedbackPage from "pages/member/Feedback";
import ProjectList from "pages/client_only/MicroProjects";
import RecombinePhotos from "pages/client_only/RecombinePhotos";
import IcebergChart from "pages/client_only/IcebergChart";

function routes(props) {
  const account = [];
  if (props.USER) {
    account.push(
      {
        name: 'route.member.details',
        route: "/member/details",
        component: <MemberAccountPage />
      },
      {
        name: 'route.member.feedback',
        route: "/member/feedback",
        component: <FeedbackPage />
      },
      {
        name: "route.member.sign-out",
        route: "/sign-out",
        component: <SignOut />
      });
  }
  else {
    account.push(
      {
        name: "route.sign-in",
        route: "/sign-in",
        component: <SignIn {...props} />,
      });
  }

  return [
    {
      name: "route.main.pages",
      icon: <Dashboard />,
      columns: 1,
      rowsPerColumn: 2,
      collapse: [
        {
          name: "route.main.general",
          collapse: [
            {
              name: "route.dashboard",
              route: "/",
              component: <DashboardPage />,
            },
            {
              name: "route.author",
              route: "/author",
              component: <Author />,
            }
          ],
        },
        {
          name: "route.main.account",
          collapse: account,
        },
        {
          name: "route.main.legal",
          collapse: [
            {
              name: "route.eula",
              route: "/eula",
              component: <EULA />
            },
            {
              name: "route.privacy",
              route: "/privacy",
              component: <Privacy />
            },
            {
              name: "route.licenses",
              route: "/licenses",
              component: <Licenses />
            }
          ]
        }
      ],
    },
    {
      name: "route.main.local-apps",
      icon: <Computer />,
      columns: 1,
      rowsPerColumn: 2,
      collapse: [
        {
          name: "route.local.general",
          collapse: [
            {
              name: "route.password-generator",
              route: "/passwordgen",
              component: <PasswordGeneratorPage />
            },
            {
              name: "route.qr-codify",
              route: "/qr",
              component: <QRCodify />
            },
            {
              name: "route.compress",
              route: "/compress",
              component: <Compress />
            },
            {
              name: "route.mind-map",
              route: "/mindmap",
              component: <MindMap />
            },
            {
              name: "route.iceberg",
              route: "/iceberg",
              component: <IcebergChart />
            },
          ]
        },
        {
          name: "route.photo-editing",
          collapse: [
            {
              name: "route.compress",
              route: "/compress",
              component: <Compress />
            },
            {
              name: "route.recombine-photos",
              route: "/recombine-photos",
              component: <RecombinePhotos />
            }
          ]
        },
        {
          name: "route.todo",
          collapse: [
            {
              name: "route.tasks",
              route: "/mytasks",
              component: <TaskList />
            },
            {
              name: "route.microprojects",
              route: "/microprojects",
              component: <ProjectList />
            },
            // {
            //   name: "route.microkanban",
            //   route: "/microkanban",
            //   component: <ProjectList />
            // }
          ]
        },
      ],
    },
    {
      isMember: true,
      name: "route.main.services",
      icon: <RoomService />,
      collapse: [
        {
          name: "route.member.uptime",
          route: "/member/uptime",
          component: <UptimeDashboard />
        }
      ]
    }
  ];
}

export default routes;
