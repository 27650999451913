import businessbio from './businessbio.jpeg';
import clipboard from './clipboard.jpeg';
import compression from './compression.jpg';
import dataextraction from './dataextraction.jpeg';
import datamapping from './datamapping.jpeg';
import device from './device.jpeg';
import embeddable from './embeddable.jpeg';
import files from './files.jpeg';
import iceberg from './iceberg.jpg';
import ledbulbs from './ledbulbs.jpeg';
import maplayers from './maplayers.jpeg';
import microprojects from './microprojects.jpg';
import mindmap from './mindmap.jpg';
import myhours from './myhours.jpeg';
import notifications from './notifications.jpeg';
import pwdgen from './password-generator-compressed.jpeg';
import personalbio from './personalbio.jpeg';
import qrcodify from './qrcodify-compressed.jpeg';
import recipes from './recipes.jpeg';
import recombine from './recombine-photos.jpg';
import tasks from './tasks.jpg';
import timer from './timer.jpeg';
import uptime from './uptime.jpg';
import writing from './writing.jpeg';
import placeholder from '../placeholders/default.jpg';

const dashboardImages = {
  businessbio,
  clipboard,
  compression,
  dataextraction,
  datamapping,
  device,
  embeddable,
  files,
  iceberg,
  ledbulbs,
  maplayers,
  microprojects,
  mindmap,
  myhours,
  notifications,
  pwdgen,
  personalbio,
  qrcodify,
  recipes,
  recombine,
  tasks,
  timer,
  uptime,
  writing,
  placeholder
};

export default dashboardImages;