import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialUIController } from "context";
import { Card, Chip, Grid, Stack } from "@mui/material";
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';

const Licenses = () => {
  const { t } = useTranslation("legal");
  const [controller] = useMaterialUIController();
  const {
    darkMode,
  } = controller;

  return (<>
    <MKBox component="section" py={2}>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          backgroundColor: ({ palette: { white, background }, functions: { rgba } }) => darkMode ? background.sidenav : rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container textAlign="center">
          <Grid item xs={12}>
            <Stack direction="column" alignItems="stretch"
              padding={6} spacing={3}>

              <MKTypography variant="h3">
                {t('licenses.title')}
              </MKTypography>

              <MKTypography variant="h3">
                {t('attributions.title')}
              </MKTypography>

              <MKTypography variant="body2">
                Built with <Chip label={"React"} icon={<MKBox component="img" width={16}
                                 src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" />}
                                 color='info'/> using Material UI from
                <br />
                <MKButton component="a" href="https://mui.com/about/">
                  MUI Team
                </MKButton>
              </MKTypography>

              <MKTypography variant="body2">
                Free Brands, Regular and Solid SVG icons from
                <br />
                <MKButton component="a" href="https://github.com/orgs/FortAwesome/people">
                  The Font Awesome Team
                </MKButton>
              </MKTypography>
              
              <MKTypography variant="body2">
                Authorization and analytics with
                <br />
                <MKButton component="a" href="https://firebase.google.com/">
                  Firebase
                </MKButton>
              </MKTypography>

              <MKTypography variant='body2'>
                Placeholder Photo by <a href="https://unsplash.com/@nasa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">NASA</a> on <a href="https://unsplash.com/photos/photo-of-outer-space-Q1p7bh3SHj8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Password Generator Photo by <a href="https://unsplash.com/@flyd2069?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">FlyD</a> on <a href="https://unsplash.com/photos/red-and-black-love-lock-zAhAUSdRLJ8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                QR Code Photo by <a href="https://unsplash.com/@brett_jordan?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Brett Jordan</a> on <a href="https://unsplash.com/photos/black-and-white-remote-control--qUp3bejuzs?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Compression Image by <a href="https://pixabay.com/users/piro4d-2707530/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1698364">PIRO</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1698364">Pixabay</a>
                <br />
                Uptime Image by <a href="https://pixabay.com/users/dlohner-4631193/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3100049">dlohner</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3100049">Pixabay</a>
                <br />
                Tasks Image by <a href="https://pixabay.com/users/ds_30-1795490/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4855775">Dmitriy</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4855775">Pixabay</a>
                <br />
                Mindmap Image by <a href="https://pixabay.com/users/biljast-2868488/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4324289">Biljana Jovanovic</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4324289">Pixabay</a>
                <br />
                Device Photo by <a href="https://unsplash.com/@hckmstrrahul?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Rahul Chakraborty</a> on <a href="https://unsplash.com/photos/black-and-blue-floral-tablet-computer-2qKrvxgPsQ8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Clipboard Photo by <a href="https://unsplash.com/@bravelyinspired?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Bethany Fidanzo</a> on <a href="https://unsplash.com/photos/purple-flower-on-white-box-and-brown-clipboard-743f0Dy8bFE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Timer Photo by <a href="https://unsplash.com/@pixelfreund?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Ralph Hutter</a> on <a href="https://unsplash.com/photos/black-and-white-analog-gauge-xLs4XSQmxtE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                My hours Photo by <a href="https://unsplash.com/@windows?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Windows</a> on <a href="https://unsplash.com/photos/person-holding-ipad-near-white-ceramic-mug-and-laptop-SwHvzwEzCfA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Notifications Photo by <a href="https://unsplash.com/@myphotos297?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">84 Video</a> on <a href="https://unsplash.com/photos/a-close-up-of-a-cell-phone-with-various-app-icons-X8ejw0g0C_g?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                My bio Photo by <a href="https://unsplash.com/@heyimmarielle_03?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Marielle Ursua</a> on <a href="https://unsplash.com/photos/two-people-sitting-at-a-table-using-their-cell-phones-XFcAJqeZ75Q?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                My business bio Photo by <a href="https://unsplash.com/@anniespratt?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Annie Spratt</a> on <a href="https://unsplash.com/photos/men-sitting-in-front-of-their-laptop-computer-MChSQHxGZrQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Map pin Photo by <a href="https://unsplash.com/@geojango_maps?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">GeoJango Maps</a> on <a href="https://unsplash.com/photos/person-holding-red-round-medication-pill-Z8UgB80_46w?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Data Photo by <a href="https://unsplash.com/@kommumikation?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Mika Baumeister</a> on <a href="https://unsplash.com/photos/white-printing-paper-with-numbers-Wpnoqo2plFA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Files Photo by <a href="https://unsplash.com/@elnazasadi?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">elnaz asadi</a> on <a href="https://unsplash.com/photos/blue-and-brown-window-blinds-kNYXNuPyTy4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Data mapping Photo by <a href="https://unsplash.com/@elnazasadi?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">elnaz asadi</a> on <a href="https://unsplash.com/photos/blue-and-brown-window-blinds-kNYXNuPyTy4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Embeddable clouds Photo by <a href="https://unsplash.com/@andreaferrario?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Andrea Ferrario</a> on <a href="https://unsplash.com/photos/pink-and-white-cumulus-cloud-QZIaFR0jQZc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Led light Photo by <a href="https://unsplash.com/@yloryb?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Ýlona María Rybka</a> on <a href="https://unsplash.com/photos/lighted-bulbs-on-ceiling-TAUybipul4Q?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Typewriter Photo by <a href="https://unsplash.com/@patrickian4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Patrick Fore</a> on <a href="https://unsplash.com/photos/black-corona-typewriter-on-brown-wood-planks-0gkw_9fy0eQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Cairo Photo by <a href="https://unsplash.com/@jackroaming?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Jack Krier</a> on <a href="https://unsplash.com/photos/white-and-blue-boat-on-water-near-city-buildings-during-daytime-1Ng-7ESDjvk?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                London Photo by <a href="https://unsplash.com/@bendavisual?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Benjamin Davies</a> on <a href="https://unsplash.com/photos/aerial-photography-of-london-skyline-during-daytime-Oja2ty_9ZLM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Bucharest Photo by <a href="https://unsplash.com/@pjgalszabo?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">PJ Gal-Szabo</a> on <a href="https://unsplash.com/photos/a-large-flower-garden-in-front-of-a-large-building-ufpm60NuTXo?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Zagreb Photo by <a href="https://unsplash.com/@aarsoph?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Kristijan Arsov</a> on <a href="https://unsplash.com/photos/beige-and-white-concrete-building-CYd_6SM_6HA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Baku Photo by <a href="https://unsplash.com/@lloydzed?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lloyd Alozie</a> on <a href="https://unsplash.com/photos/city-skyline-under-blue-sky-during-daytime-wX_9ZhIErpg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Almaty Photo by <a href="https://unsplash.com/@pigleting?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Alexander Serzhantov</a> on <a href="https://unsplash.com/photos/high-rise-buildings-beside-green-trees-during-daytime-ECRotWQ6T-M?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Berlin Photo by <a href="https://unsplash.com/@vradenburg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Adam Vradenburg</a> on <a href="https://unsplash.com/photos/needle-tower-among-small-buildings-g8DI2ZZFt8I?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Paris Photo by <a href="https://unsplash.com/@kalimullin?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Ilnur Kalimullin</a> on <a href="https://unsplash.com/photos/group-of-people-walking-near-body-of-water-and-building-under-blue-sky-at-daytime-CB0Qrf8ib4I?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Micro-projects Photo by <a href="https://unsplash.com/@markuswinkler?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Markus Winkler</a> on <a href="https://unsplash.com/photos/white-printer-paper-beside-silver-laptop-computer-Q2J2qQsoYH8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                <br />
                Recombine Image by <a href="https://pixabay.com/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1668918">Gerd Altmann</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1668918">Pixabay</a>
                <br />
                Iceberg Photo by <a href="https://unsplash.com/@simonppt?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">SIMON LEE</a> on <a href="https://unsplash.com/photos/a-large-iceberg-floating-in-the-middle-of-a-body-of-water-N4RYO1198KM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
              </MKTypography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </MKBox>
  </>
  );
}

export default Licenses;