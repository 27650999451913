const example = `id,weight,prio,status,title,phase,group
O-1,8,1,done,Title of first task,1,1
O-2,13,1,done,Second story,1,1
O-3,8,1,in qa,Story under testing,1,1
O-4,5,2,in qa,Second story under testing,1,1
O-12,3,1,in qa,Story under testing,1,1
O-13,5,2,in qa,Second story under testing,1,1
O-14,2,1,in qa,Story under testing,1,1
O-15,5,2,in qa,Second story under testing,1,1
O-5,5,1,in dev,Progressing,1,1
O-6,3,1,in dev,Very progressing,1,1
O-7,2,2,in dev,Very much not progressing,1,2
O-8,2,1,todo,Ready or not,1,1
O-9,4,2,todo,Here I come,1,1
O-10,2,1,todo,Ready or not,1,1
O-11,4,2,todo,Here I come,1,1
O-16,2,1,todo,Ready or not,1,2
O-17,4,2,todo,Here I come,1,2
O-18,2,1,todo,Ready or not,1,2
O-19,4,2,todo,Here I come,1,3
O-20,2,1,todo,Ready or not,1,3
O-21,4,2,todo,Here I come,1,3
O-22,2,1,todo,Ready or not,1,3
O-23,4,2,todo,Here I come,1,3
O-1,8,1,done,Title of first task,2,1
O-2,13,1,done,Second story,2,1
O-3,8,1,done,Story under testing,2,1
O-4,5,2,done,Second story under testing,2,1
O-12,3,1,done,Story under testing,2,1
O-13,5,2,in qa,Second story under testing,2,1
O-14,2,1,in qa,Story under testing,2,1
O-15,5,2,in qa,Second story under testing,2,1
O-5,5,1,in qa,Progressing,2,1
O-6,3,1,in qa,Very progressing,2,1
O-7,2,2,in qa,Very much not progressing,2,2
O-8,2,1,in dev,Ready or not,2,1
O-9,4,2,in dev,Here I come,2,1
O-10,2,1,in dev,Ready or not,2,1
O-11,4,2,in dev,Here I come,2,1
O-16,2,1,todo,Ready or not,2,2
O-17,4,2,todo,Here I come,2,2
O-18,2,1,todo,Ready or not,2,2
O-19,4,2,todo,Here I come,2,3
O-20,2,1,todo,Ready or not,2,3
O-21,4,2,todo,Here I come,2,3
O-22,2,1,todo,Ready or not,2,3
O-23,4,2,todo,Here I come,2,3
O-1,8,1,done,Title of first task,3,1
O-2,13,1,done,Second story,3,1
O-3,8,1,done,Story under testing,3,1
O-4,5,2,done,Second story under testing,3,1
O-12,3,1,done,Story under testing,3,1
O-13,5,2,done,Second story under testing,3,1
O-14,2,1,done,Story under testing,3,1
O-15,5,2,done,Second story under testing,3,1
O-5,5,1,done,Progressing,3,1
O-6,3,1,in qa,Very progressing,3,1
O-7,2,2,in qa,Very much not progressing,3,2
O-8,2,1,in qa,Ready or not,3,1
O-9,4,2,in qa,Here I come,3,1
O-10,2,1,in qa,Ready or not,3,1
O-11,4,2,in dev,Here I come,3,1
O-16,2,1,in dev,Ready or not,3,2
O-17,4,2,in dev,Here I come,3,2
O-18,2,1,in dev,Ready or not,3,2
O-19,4,2,in dev,Here I come,3,3
O-20,2,1,todo,Ready or not,3,3
O-21,4,2,todo,Here I come,3,3
O-22,2,1,todo,Ready or not,3,3
O-23,4,2,todo,Here I come,3,3
O-1,8,1,done,Title of first task,4,1
O-2,13,1,done,Second story,4,1
O-3,8,1,done,Story under testing,4,1
O-4,5,2,done,Second story under testing,4,1
O-12,3,1,done,Story under testing,4,1
O-13,5,2,done,Second story under testing,4,1
O-14,2,1,done,Story under testing,4,1
O-15,5,2,done,Second story under testing,4,1
O-5,5,1,done,Progressing,4,1
O-6,3,1,done,Very progressing,4,1
O-7,2,2,done,Very much not progressing,4,2
O-8,2,1,done,Ready or not,4,1
O-9,4,2,done,Here I come,4,1
O-10,2,1,in qa,Ready or not,4,1
O-11,4,2,in qa,Here I come,4,1
O-16,2,1,in qa,Ready or not,4,2
O-17,4,2,in qa,Here I come,4,2
O-18,2,1,in qa,Ready or not,4,2
O-19,4,2,in dev,Here I come,4,3
O-20,2,1,in dev,Ready or not,4,3
O-21,4,2,in dev,Here I come,4,3
O-22,2,1,in dev,Ready or not,4,3
O-23,4,2,todo,Here I come,4,3
O-1,8,1,done,Title of first task,5,1
O-2,13,1,done,Second story,5,1
O-3,8,1,in qa,Story under testing,5,1
O-4,5,2,in qa,Second story under testing,5,1
O-12,3,1,in qa,Story under testing,5,1
O-13,5,2,in qa,Second story under testing,5,1
O-14,2,1,in qa,Story under testing,5,1
O-15,5,2,in qa,Second story under testing,5,1
O-5,5,1,in dev,Progressing,5,1
O-6,3,1,in dev,Very progressing,5,1
O-7,2,2,in dev,Very much not progressing,5,2
O-8,2,1,todo,Ready or not,5,1
O-9,4,2,todo,Here I come,5,1
O-10,2,1,todo,Ready or not,5,1
O-11,4,2,todo,Here I come,5,1
O-16,2,1,todo,Ready or not,5,2
O-17,4,2,todo,Here I come,5,2
O-18,2,1,todo,Ready or not,5,2
O-19,4,2,todo,Here I come,5,3
O-20,2,1,todo,Ready or not,5,3
O-21,4,2,todo,Here I come,5,3
O-22,2,1,todo,Ready or not,5,3
O-23,4,2,todo,Here I come,5,3
O-1,8,1,done,Title of first task,6,1
O-2,13,1,done,Second story,6,1
O-3,8,1,done,Story under testing,6,1
O-4,5,2,done,Second story under testing,6,1
O-12,3,1,done,Story under testing,6,1
O-13,5,2,in qa,Second story under testing,6,1
O-14,2,1,in qa,Story under testing,6,1
O-15,5,2,in qa,Second story under testing,6,1
O-5,5,1,in qa,Progressing,6,1
O-6,3,1,in qa,Very progressing,6,1
O-7,2,2,in qa,Very much not progressing,6,2
O-8,2,1,in dev,Ready or not,6,1
O-9,4,2,in dev,Here I come,6,1
O-10,2,1,in dev,Ready or not,6,1
O-11,4,2,in dev,Here I come,6,1
O-16,2,1,todo,Ready or not,6,2
O-17,4,2,todo,Here I come,6,2
O-18,2,1,todo,Ready or not,6,2
O-19,4,2,todo,Here I come,6,3
O-20,2,1,todo,Ready or not,6,3
O-21,4,2,todo,Here I come,6,3
O-22,2,1,todo,Ready or not,6,3
O-23,4,2,todo,Here I come,6,3
O-1,8,1,done,Title of first task,7,1
O-2,13,1,done,Second story,7,1
O-3,8,1,done,Story under testing,7,1
O-4,5,2,done,Second story under testing,7,1
O-12,3,1,done,Story under testing,7,1
O-13,5,2,done,Second story under testing,7,1
O-14,2,1,done,Story under testing,7,1
O-15,5,2,done,Second story under testing,7,1
O-5,5,1,done,Progressing,7,1
O-6,3,1,in qa,Very progressing,7,1
O-7,2,2,in qa,Very much not progressing,7,2
O-8,2,1,in qa,Ready or not,7,1
O-9,4,2,in qa,Here I come,7,1
O-10,2,1,in qa,Ready or not,7,1
O-11,4,2,in dev,Here I come,7,1
O-16,2,1,in dev,Ready or not,7,2
O-17,4,2,in dev,Here I come,7,2
O-18,2,1,in dev,Ready or not,7,2
O-19,4,2,in dev,Here I come,7,3
O-20,2,1,todo,Ready or not,7,3
O-21,4,2,todo,Here I come,7,3
O-22,2,1,todo,Ready or not,7,3
O-23,4,2,todo,Here I come,7,3
O-1,8,1,done,Title of first task,8,1
O-2,13,1,done,Second story,8,1
O-3,8,1,done,Story under testing,8,1
O-4,5,2,done,Second story under testing,8,1
O-12,3,1,done,Story under testing,8,1
O-13,5,2,done,Second story under testing,8,1
O-14,2,1,done,Story under testing,8,1
O-15,5,2,done,Second story under testing,8,1
O-5,5,1,done,Progressing,8,1
O-6,3,1,done,Very progressing,8,1
O-7,2,2,done,Very much not progressing,8,2
O-8,2,1,done,Ready or not,8,1
O-9,4,2,done,Here I come,8,1
O-10,2,1,in qa,Ready or not,8,1
O-11,4,2,in qa,Here I come,8,1
O-16,2,1,in qa,Ready or not,8,2
O-17,4,2,in qa,Here I come,8,2
O-18,2,1,in qa,Ready or not,8,2
O-19,4,2,in dev,Here I come,8,3
O-20,2,1,in dev,Ready or not,8,3
O-21,4,2,in dev,Here I come,8,3
O-22,2,1,in dev,Ready or not,8,3
O-23,4,2,todo,Here I come,8,3
O-1,8,1,done,Title of first task,9,1
O-2,13,1,done,Second story,9,1
O-3,8,1,in qa,Story under testing,9,1
O-4,5,2,in qa,Second story under testing,9,1
O-12,3,1,in qa,Story under testing,9,1
O-13,5,2,in qa,Second story under testing,9,1
O-14,2,1,in qa,Story under testing,9,1
O-15,5,2,in qa,Second story under testing,9,1
O-5,5,1,in dev,Progressing,9,1
O-6,3,1,in dev,Very progressing,9,1
O-7,2,2,in dev,Very much not progressing,9,2
O-8,2,1,todo,Ready or not,9,1
O-9,4,2,todo,Here I come,9,1
O-10,2,1,todo,Ready or not,9,1
O-11,4,2,todo,Here I come,9,1
O-16,2,1,todo,Ready or not,9,2
O-17,4,2,todo,Here I come,9,2
O-18,2,1,todo,Ready or not,9,2
O-19,4,2,todo,Here I come,9,3
O-20,2,1,todo,Ready or not,9,3
O-21,4,2,todo,Here I come,9,3
O-22,2,1,todo,Ready or not,9,3
O-23,4,2,todo,Here I come,9,3
O-1,8,1,done,Title of first task,10,1
O-2,13,1,done,Second story,10,1
O-3,8,1,done,Story under testing,10,1
O-4,5,2,done,Second story under testing,10,1
O-12,3,1,done,Story under testing,10,1
O-13,5,2,in qa,Second story under testing,10,1
O-14,2,1,in qa,Story under testing,10,1
O-15,5,2,in qa,Second story under testing,10,1
O-5,5,1,in qa,Progressing,10,1
O-6,3,1,in qa,Very progressing,10,1
O-7,2,2,in qa,Very much not progressing,10,2
O-8,2,1,in dev,Ready or not,10,1
O-9,4,2,in dev,Here I come,10,1
O-10,2,1,in dev,Ready or not,10,1
O-11,4,2,in dev,Here I come,10,1
O-16,2,1,todo,Ready or not,10,2
O-17,4,2,todo,Here I come,10,2
O-18,2,1,todo,Ready or not,10,2
O-19,4,2,todo,Here I come,10,3
O-20,2,1,todo,Ready or not,10,3
O-21,4,2,todo,Here I come,10,3
O-22,2,1,todo,Ready or not,10,3
O-23,4,2,todo,Here I come,10,3
O-1,8,1,done,Title of first task,11,1
O-2,13,1,done,Second story,11,1
O-3,8,1,done,Story under testing,11,1
O-4,5,2,done,Second story under testing,11,1
O-12,3,1,done,Story under testing,11,1
O-13,5,2,done,Second story under testing,11,1
O-14,2,1,done,Story under testing,11,1
O-15,5,2,done,Second story under testing,11,1
O-5,5,1,done,Progressing,11,1
O-6,3,1,in qa,Very progressing,11,1
O-7,2,2,in qa,Very much not progressing,11,2
O-8,2,1,in qa,Ready or not,11,1
O-9,4,2,in qa,Here I come,11,1
O-10,2,1,in qa,Ready or not,11,1
O-11,4,2,in dev,Here I come,11,1
O-16,2,1,in dev,Ready or not,11,2
O-17,4,2,in dev,Here I come,11,2
O-18,2,1,in dev,Ready or not,11,2
O-19,4,2,in dev,Here I come,11,3
O-20,2,1,todo,Ready or not,11,3
O-21,4,2,todo,Here I come,11,3
O-22,2,1,todo,Ready or not,11,3
O-23,4,2,todo,Here I come,11,3
O-1,8,1,done,Title of first task,12,1
O-2,13,1,done,Second story,12,1
O-3,8,1,done,Story under testing,12,1
O-4,5,2,done,Second story under testing,12,1
O-12,3,1,done,Story under testing,12,1
O-13,5,2,done,Second story under testing,12,1
O-14,2,1,done,Story under testing,12,1
O-15,5,2,done,Second story under testing,12,1
O-5,5,1,done,Progressing,12,1
O-6,3,1,done,Very progressing,12,1
O-7,2,2,done,Very much not progressing,12,2
O-8,2,1,done,Ready or not,12,1
O-9,4,2,done,Here I come,12,1
O-10,2,1,in qa,Ready or not,12,1
O-11,4,2,in qa,Here I come,12,1
O-16,2,1,in qa,Ready or not,12,2
O-17,4,2,in qa,Here I come,12,2
O-18,2,1,in qa,Ready or not,12,2
O-19,4,2,in dev,Here I come,12,3
O-20,2,1,in dev,Ready or not,12,3
O-21,4,2,in dev,Here I come,12,3
O-22,2,1,in dev,Ready or not,12,3
O-23,4,2,todo,Here I come,12,3`;

export default example;