import PropTypes from 'prop-types';
import * as d3 from 'd3';
import "./treemap.css";

const Treemap = ({ width, heightFactor, data, colors }) => {
  const hierarchy = d3.hierarchy({
    type: 'node',
    children: data
  }).sort((d) => d.priority).sum((d) => d.value);
  const height = hierarchy.value * (heightFactor ?? 5);

  const treeGenerator = d3.treemap().size([width, height]).padding(4).tile(d3.treemapResquarify.ratio(2));
  const root = treeGenerator(hierarchy);
  //console.log({ hierarchy, leaves: root.leaves() });

  const allShapes = root.leaves().map((leaf) => {
    return (
      <g key={leaf.id} className={".rectangle"}>
        <rect key="box"
          x={leaf.x0}
          y={leaf.y0}
          width={leaf.x1 - leaf.x0}
          height={leaf.y1 - leaf.y0}
          stroke="transparent"
          fill={colors[parseInt(leaf.data.group ?? "0")] ?? "#AAAAAA"}
          //className={'opacity-80 hover:opacity-100'}
          // onMouseEnter={() => {

          // }}
          // onMouseLeave={() => {

          // }}
        >
          <title>{leaf.data.name} - {leaf.data.title}</title>
        </rect>
        <text key="name"
          x={leaf.x0 + 3}
          y={leaf.y0 + 3}
          fontSize={12}
          textAnchor="start"
          alignmentBaseline="hanging"
          fill="white"
          className="font-bold"
        >
          {leaf.data.name}
        </text>
        {/* <text
          x={leaf.x0 + 3}
          y={leaf.y0 + 18}
          fontSize={12}
          textAnchor="start"
          alignmentBaseline="hanging"
          fill="white"
          className="font-light"
        >
          {leaf.data.value}
        </text> */}
      </g>
    );
  });

  return (
    <div>
      <svg width={width} height={height} className={".container"}>
        {allShapes}
      </svg>
    </div>
  );
};

Treemap.props = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.object,
};

export default Treemap;
